import { useState, useEffect } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "./logo.png"
import c1 from "./c1.png"
import Toast from "./toast";



export default function Contact() {
    const [loading, setLoading] = useState(false)
    return (
        <Flex w="100%" px={["5", "8%"]} direction={["column", "row"]}>
            <Flex w={["100%", "55%"]} direction="column" py={["40px", "80px"]} pr={["0", "10%"]}>
                <Text fontSize={["26px", "40px"]} data-aos="fade-down" fontWeight="700">Contact<Text as="span" fontWeight="300"> Us</Text></Text>
                <Text mb="8" data-aos="fade-up" fontSize={["13px", "14px"]} color="slategray" mt={["2", "0"]}>At Gift-Fy We are always here to assist you with any questions or concerns you may have about our platform or digital gift cards</Text>
                <Image w="150px" src={Logo} />

                <Flex w="100%" direction="column" data-aos="fade-up" mt="5">
                    <Flex align="center" mb="2" fontSize="18px"><i className="mdi mdi-email-outline"></i><Text fontSize="14px" ml="2">Email</Text></Flex>
                    <input id="t1" style={{ width: "100%", border: "1px solid #09203F", padding: "15px 28px", borderRadius: "8px" }} placeholder="Enter your email" />
                    <Flex mt="5" align="center" mb="2" fontSize="18px"><i className="mdi mdi-chat-outline"></i><Text fontSize="14px" ml="2">Message</Text></Flex>
                    <textarea id="t2" style={{ width: "100%", border: "1px solid #09203F", padding: "15px 28px", borderRadius: "8px", resize: "none", height: "120px" }} placeholder="Enter your message"></textarea> 
                    
                    <Flex mt="8"><Flex w="100%" bg="#000" border="2px solid #000" color="#fff" borderRadius="30px" padding="15px 40px" fontSize={["14px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => {
                        var a = document.getElementById("t1")
                        var b = document.getElementById("t2")
                        if(a.value !== "" && b.value !== "") {
                            setLoading(true)
                            setTimeout(function() {
                                setLoading(false)
                                a.value = ""
                                b.value = ""
                                Toast("Your feedback has been recieved, someone from our team will reach out to you via email", "success")
                            }, 2000)
                        }
                        else {
                            Toast("Kindly fill the required fields", "error")
                        }
                    }}>{loading ? <Spinner /> : <><Text fontSize="16px" mr="2"><i className="mdi mdi-email"></i></Text> Send</>}</Flex>
                    </Flex>
                </Flex>

            </Flex>

            <Flex w={["100%", "45%"]} direction="column" pl={["0", "5%"]} py="80px">
                <Image src={c1} w="100%" mt="auto" bgColor="#0d1332" borderRadius="8px" />
                <Text data-aos="fade-up" color="slategray" mt="12" fontSize={["13px", "14px"]}>For any concerns or issues with a specific digital gift card purchase, please include your order number and any relevant details in your message, and we will do our best to assist you or you can click our customer support!</Text>
                <Flex mt="8"><Flex w="100%" bg="#000" border="2px solid #000" color="#fff" borderRadius="30px" padding="15px 40px" fontSize={["14px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => {
                    Toast("Customer Support", "success")
                    window.tidioChatApi.open()
                }}><Text fontSize="16px" mr="2"><i className="mdi mdi-message-reply-text"></i></Text> Contact Support</Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}