import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
  	ChakraProvider,
	Spinner,
} from "@chakra-ui/react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
  } from "react-router-dom";
import ScrollToTop from "./sc";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Nav from './nav';
import Landing from './landing';
import Footer from "./footer"
import About from './about';
import Contact from './contact';
import Buy from './buy';

function App() {
	useEffect(() => {
		Aos.init({ duration: 900 })
	}, [])
	const [cardName, setCardName] = useState("")
	const [cardDp, setCardDp] = useState("")

	return (
		<ChakraProvider>
			<Flex w="100%" fontSize="15px">
				<Router>				
					<ScrollToTop>
						<Flex w="100%" direction="column" color="#000">
							<Nav />
							<Switch>
								<Route exact path="/"><Landing setCardDp={setCardDp} setCardName={setCardName} cardName={cardName} /></Route>
								<Route path="/about"><About /></Route>
								<Route path="/contact"><Contact /></Route>
								<Route path="/buy"><Buy cardName={cardName} cardDp={cardDp} /></Route>
								<Route><Landing setCardDp={setCardDp} setCardName={setCardName} cardName={cardName} /></Route>
							</Switch>
							<Footer />
						</Flex>
					</ScrollToTop>
				</Router>
			</Flex>
		</ChakraProvider>
	)
}

export default App;
