import { Flex, Text, Image } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Logo from "./logo.png"
import Toast from "./toast";
import { useState, useEffect } from "react";



export default function Nav() {
    const history = useHistory()
    const [bgC, setBgC] = useState("none")
    const [menu, setMenu] = useState(false)

    const handleNavScroll = () => {
        const position = window.pageYOffset;
        if (position > 15) {
            setBgC("0 3px 5px -1px rgba(20, 20, 20, .05)")
        } else {
            setBgC("none")
        }
	};

	useEffect(() => {
        window.addEventListener('scroll', handleNavScroll);
        return () => {
            window.removeEventListener('scroll', handleNavScroll);
        };
	});
    return (
        <>
            <Flex py="1.5" justify="center" bg="#000" color="#fff" fontSize="10px" fontWeight="600"><Text mr="5">Fast.</Text><Text mr="5">Secured.</Text><Text>Reliable</Text></Flex>

            <Flex position="sticky" top="0" left="0" w="100%" px={["5", "8%"]} backdropFilter="blur(8px)" bg="rgba(255,255,255,0.8)" py="3" align="center" zIndex="100" boxShadow={bgC}>
                <Flex w={["50%", "30%"]} align="center">
                    <Image src={Logo} w={["35px", "50px"]} cursor="pointer" onClick={() => history.push("/")} />
                    <Text fontSize={["16px", "20px"]} fontWeight="700" ml="3">Gift<Text as="span" fontWeight="400">-Fy</Text></Text>
                </Flex>
                
                <Flex w={["50%", "70%"]} justify="flex-end" align="center" fontSize="14px">
                    <Flex display={["none", "flex"]}>
                    {
                        ["About", "Contact", "Buy Card", "Validate Card"].map((item, index) => (
                            <Text key={index} fontWeight="400" mr="12" cursor="pointer" transition="300ms ease-in-out" _hover={{ fontWeight: "bold", letterSpacing: "3px" }} onClick={() => {
                                if(index === 0) {
                                    history.push("/about")
                                }
                                else if(index === 1) {
                                    history.push("/contact")
                                }
                                else {
                                    history.push("/")
                                    setTimeout(function() {
                                        const element = document.getElementById('pvArea')
                                        return window.scrollTo({
                                        top: element ? element.offsetTop - 50 : 0,
                                        behavior: 'smooth',
                                        })
                                    }, 100)
                                }
                            }}>{item}</Text>
                        ))
                    }
                    </Flex>

                    <Flex w={["40px", "45px"]} h={["40px", "45px"]} cursor="pointer" justify="center" align="center" color="#000" bg="rgb(235,235,235)"  _hover={{ transform: "translate(0px, 4px)" }} transition="300ms ease-in-out" borderRadius="100%" fontSize={["17px", "20px"]} mr="4" onClick={() => {
                        Toast("Customer Support", "success")
                        window.tidioChatApi.open()
                    }}><i className="mdi mdi-email"></i></Flex>
                    
                    <Flex w={["40px", "45px"]} h={["40px", "45px"]} cursor="pointer" justify="center" align="center" color="#000" bg="rgb(235,235,235)" _hover={{ transform: "translate(0px, 4px)" }} transition="300ms ease-in-out" borderRadius="100%" fontSize={["17px", "20px"]} onClick={() => setMenu(true)}><i className="mdi mdi-menu"></i></Flex>
                </Flex>
            </Flex>


            {
            menu && 
            <Flex w="100%" h="100%" position="fixed" top="0" left="0" zIndex="1000000" bg="rgba(0,0,0,0.7)" backdropFilter="blur(12px)" justify="flex-end">
                <Flex w={["80%", "600px"]} h="100%" bg="#fff" direction="column" px={["5", "12"]} py={["12", "6"]} data-aos="fade-left">
                    <Flex ml="auto" bg="rgb(235,235,235)" justify="center" align="center" color="#000" fontSize={["20px", "30px"]} cursor="pointer" borderRadius="100%" h={["40px", "50px"]} w={["41px", "51px"]} zIndex="10" onClick={() => setMenu(false)}><i className="mdi mdi-close"></i></Flex>
                    <Flex mt={["-12", "2"]} justify="center" flex="1" direction="column">
                        <Text fontSize={["25px", "40px"]} mb="5"><Text fontWeight="700" as="span">Quick</Text> Links</Text>
                        {
                            ["Home", "About", "Contact", "Buy", "Validate"].map((item, index) => (
                                <Flex key={index} _hover={{ letterSpacing: "5px", fontWeight: "700" }} transition="300ms ease-in-out" cursor="pointer" fontWeight="400" fontSize={["15px", "18px"]} pt="4" pb="3" borderBottom="1px solid rgb(202, 202, 205)" align="center" onClick={() => {
                                    history.push(index === 0 ? "/" : index === 1 ? "/about" : index === 2 ? "/contact" : "/")
                                    if(index > 2) {
                                        setTimeout(function() {
                                            const element = document.getElementById('pvArea')
                                            return window.scrollTo({
                                            top: element ? element.offsetTop - 50 : 0,
                                            behavior: 'smooth',
                                            })
                                        }, 100)
                                    }
                                    setMenu(false)
                                }}><Text mr="4" fontSize={["18px", "20px"]}><i className={index === 0 ? "mdi mdi-home-outline" : index === 1 ? "mdi mdi-information-outline" : index === 2 ? "mdi mdi-message-outline" : index === 3 ? "mdi mdi-currency-usd" : "mdi mdi-lock-open-outline"}></i></Text>{item}</Flex>
                            ))
                        }
                    </Flex>
                </Flex>
            </Flex>
            }
        </>
    )
}